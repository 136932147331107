import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Particle from '../Particle'

export default function Clickedover() {
    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading" style={{ color: "white", marginBottom:"30%"}}>
                     You are Running Out of Credit
                     contact to the owner
                </h1>

                <p >
           
                </p>


                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

                </Row>

            </Container>
        </Container>
    )
}
