import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";


import chatimage from "../../Assets/Projects/chatapp.png";
import iotimage from "../../Assets/Projects/iotproject.png";
const defaultimage = '#'



export default function Project() {
    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    Web development<strong className="purple"> Project </strong>
                </h1>
                <p style={{ color: "white" }}>
                    My Graduation Projects
                </p>

                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={chatimage}
                            isBlog={false}
                            title="Chat Application"
                            description="Introducing my messenger application, a user-friendly platform for seamless communication. Users can create accounts easily and manage contacts efficiently. here I used the socket.io to real life communication and notification without reloading the page . for the security purpose here bycrpt library is used to hash the password ."
                            demoLink="#"
                            btninfo="Go to Project"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={iotimage}
                            isBlog={false}
                            title="IOT Application"
                            description="This was a group project I worked on during my 5th semester. We used a Raspberry Pi board and a web application to control home appliances remotely. The main idea was to help people avoid leaving devices on when they're not at home, like when they're at work. With our system, users can easily turn off appliances from anywhere 
        
                            Also, it makes homes safer because users can turn on lights to make it look like someone's home, even when they're not. "
                            demoLink="https://iotworld.vercel.app/"
                            btninfo="Go to Project"
                        />
                    </Col>


                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={defaultimage}
                            isBlog={false}

                            title="Argumented Reality Project"

                            description=""
                            demoLink="#"
                            btninfo="Go to Project"
                        />

                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={defaultimage}
                            isBlog={false}

                            title="Study Platoform"

                            description=""

                            demoLink="#"
                            btninfo="Go to Project"
                        />
                    </Col>

                </Row>
            </Container>
        </Container>
    );
}
